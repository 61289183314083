<template>
  <div class="layout-container">
    <customer-page-bar />
    <div class="banner-container">
      <v-row class="primary customer-layout ma-0">
        <v-container fluid class="customer-layout-child d-flex justify-end pa-0">
          <v-toolbar-items class="d-flex align-center pr-5">
            <span class="text-h4 white--text font-weight-bold">{{ $t('layouts.customerpagelayout.hallo', { '1': name }) }}</span>
          </v-toolbar-items>
        </v-container>
      </v-row>
    </div>
    <v-main class="customer-container">
      <slot />
    </v-main>
    <br>
    <br>
    <customer-page-footer />
  </div>
</template>

<script>
  import CustomerPageBar from '@/layouts/CustomerPageBar.vue'
  import CustomerPageFooter from '@/layouts/CustomerPageFooter.vue'
  import CustomerService from '@/services/CustomerService'

  export default {
    name: 'CustomerPageLayout',
    components: {
      CustomerPageBar,
      CustomerPageFooter,
    },
    data () {
      return {
        name: sessionStorage.getItem('name'),
      }
    },
    created () {
      CustomerService.getCustomerInfos()
        .then((response) => {
          const customer = response.data
          if (customer.language) {
            this.$root.$i18n.locale = customer.language.locale
          }
        })
    },
  }
</script>

<style>
  .layout-container {
    margin-top: 128px !important;
  }

  .banner-container {
    width: 100%;
    height: 40px;
    margin-bottom: 0.5rem;
  }

  .customer-container {
    min-height: 84.6vh;
  }

  .customer-layout {
    min-height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .customer-layout-child {
    min-height: inherit;
    display: flex;
    align-items: center;
  }

  @media only screen and (max-width: 1045px) {
    .layout-container {
      margin-top: 70px !important;
    }

    .customer-layout {
      margin-top: 1rem;
    }

    .customer-layout-child {
      margin-top: 0;
    }
  }
</style>
