import { CapacitorHttp } from '@capacitor/core'

const baseURL = process.env.VUE_APP_API_URL

const addAuthHeader = (options) => {
  const token = sessionStorage.getItem('token')
  if (token) {
    options.headers = {
      ...options.headers,
      Authorization: `Bearer ${token}`,
    }
  }
  return options
}

const handleResponse = async (promise) => {
  try {
    const response = await promise
    return { data: response.data, status: response.status, headers: response.headers }
  } catch (error) {
    if (error.status === 401) {
      sessionStorage.removeItem('token')
      window.location.reload()
      return
    }
    throw error
  }
}

const http = {
  async get(url, config = {}) {
    const options = addAuthHeader({
      url: `${baseURL}${url}`,
      headers: {
        'Accept': 'application/json',
        ...config.headers || {},
      },
      params: config.params,
    })
    return handleResponse(CapacitorHttp.get(options))
  },

  async post(url, data, config = {}) {
    const options = addAuthHeader({
      url: `${baseURL}${url}`,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        ...config.headers || {},
      },
      data,
    })
    return handleResponse(CapacitorHttp.post(options))
  },

  async put(url, data, config = {}) {
    const options = addAuthHeader({
      url: `${baseURL}${url}`,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        ...config.headers || {},
      },
      data,
    })
    return handleResponse(CapacitorHttp.put(options))
  },

  async delete(url, config = {}) {
    const options = addAuthHeader({
      url: `${baseURL}${url}`,
      headers: {
        'Accept': 'application/json',
        ...config.headers || {},
      },
    })
    return handleResponse(CapacitorHttp.delete(options))
  },
}

export default http
