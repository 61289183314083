<template>
  <v-footer
    class="ma-0 pa-0 customer-footer"
    color="footer"
    justify="center"
    align="start"
  >
    <v-container class="py-2 pb-10">
      <v-row justify="center">
        <v-col cols="12">
          <div class="links-container">
            <div 
              v-for="(link, index) in footerLinks"
              :key="link.title"
              class="link-item"
            >
              <router-link
                :to="link.link"
                class="white--text text-decoration-none"
              >
                {{ link.title }}
              </router-link>
              <span v-if="index < footerLinks.length - 1" class="separator">|</span>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
  export default {
    computed: {
      footerLinks() {
        return [
          { 
            title: this.$t('layouts.core.appfooteranonymous.imprint'), 
            link: '/imprint'
          },
          { 
            title: this.$t('layouts.core.appfooteranonymous.termsOfUse'), 
            link: '/termsOfUse'
          },
          { 
            title: this.$t('layouts.core.appfooteranonymous.privacy'), 
            link: '/privacy'
          },
        ]
      }
    }
  }
</script>

<style scoped>
  .v-btn:before {
    bottom: unset !important;
  }
  .links-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 36px;
  }
  
  .separator {
    margin: 0 5px;
    color: white;
  }

  .customer-footer {
    width: 100%;
    position: relative;
    bottom: 0;
  }

  .link-item {
    display: inline-flex;
    align-items: center;
  }

  @media(max-width: 650px) {
    .links-container {
      flex-direction: column;
      padding: 8px 0;
    }
    .link-item {
      margin-bottom: 0.5rem;
    }
    .separator {
      margin: 0;
      display: none;
    }
    .link-item:last-child {
      margin-bottom: 0;
    }
  }
</style>